import React from 'react';
import styled from 'styled-components';
import logo from './assets/logo.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f8f8;
  text-align: center;
  padding: 20px;
`;

const Logo = styled.img`
  width: 120px; /* Adjust the size as needed */
  height: auto;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font-size: 36px;
  color: #000000;
  margin-bottom: 20px;
`;

const Subheading = styled.h2`
  font-size: 24px;
  color: #555555;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Button = styled.a`
  display: inline-block;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  color: #ffffff;
  background-color: #000000;
  border: 2px solid #000000;
  transition: background-color 0.3s, color 0.3s;
  
  &:hover {
    background-color: #ffffff;
    color: #000000;
  }
`;

const LandingPage = () => {
    return (
        <Container>
            <Logo src={logo} alt="1Point Logo" />
            <Heading>Coming Soon</Heading>
            <Subheading>1Point is launching soon on the App Store and Play Store!</Subheading>
        </Container>
    );
};

export default LandingPage;