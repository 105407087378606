import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { useLocation } from "react-router-dom";
import { auth } from "./firebase/firebase.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from './assets/logo.png';


const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [complete, setComplete] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const actionCode = queryParams.get('oobCode');

    useEffect(() => {
        // Verify the password reset code is valid.
        verifyPasswordResetCode(auth, actionCode)
            .then((email) => {
                setEmail(email);
            })
            .catch((error) => {
                setError(error.message);
            });
    }, [actionCode]);

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSave = (e) => {
        e.preventDefault();
        confirmPasswordReset(auth, actionCode, password)
            .then(() => {
                console.log("Password reset successful");
                // hide the form AND show a success message
                setComplete(true);

                // toast success message
                toast.success("Password reset successful");



            })
            .catch((error) => {
                setError(error.message);
            });
    };

    return (
        <>
            <div className="reset-box">
                <img class="center" width="100" height="100" src={logo} alt="1Point Logo" />
                <h2>Reset your password</h2>
                {error ? (
                    <p>Error: {error}</p>
                ) : (
                    <p>
                        for <strong>{email}</strong>
                    </p>
                )}
                {!complete &&
                    <form onSubmit={handleSave}>

                        <div className="input-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="New password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                            <span className="toggle-password" onClick={togglePasswordVisibility}>
                                {showPassword ? "🙈" : "👁️"}
                            </span>
                        </div>
                        <button type="submit" className="save-btn">
                            SAVE
                        </button>
                    </form>
                }
                <ToastContainer position="bottom-center"
                />
            </div></>


    )
};

export default ResetPassword;
