import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import * as React from 'react'
import { Navigate } from 'react-router-dom';
import TermsOfService from './TermsOfService';
import LandingPage from './Landing';
import ResetPassword from './ResetPassword';


function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/tc" element={<TermsOfService />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/reset/*" element={<ResetPassword />} />
          <Route path="*" element={<Navigate replace to="/landing" />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
