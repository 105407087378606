// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, initializeAuth, browserLocalPersistence } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBzcZo-OJGLw8IQupUTaB5G4YMWmAVfMC0",
  authDomain: "point-admin.firebaseapp.com",
  projectId: "point-admin",
  storageBucket: "point-admin.firebasestorage.app",
  messagingSenderId: "114046315541",
  appId: "1:114046315541:web:28ac35c2c9ced79293321c"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Explicitly initialize Firebase Authentication
const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});

export { app, auth };